.mario-sprite {
    width: 32px;
    height: 32px;
    background-image: url('../img/mario-sprite.png');
    background-repeat: no-repeat;
    image-rendering: pixelated;
    transform-origin: center;
}

.mario-idle {
    background-position: -32px 0;
}

.mario-walk {
    animation: mario-walk 0.5s steps(3) infinite;
}

.mario-jump {
    background-position: -160px 0;
}

.mario-timeline {
    position: absolute;
    transform: rotate(90deg) translateX(-50%);
    animation: mario-walk 0.5s steps(3) infinite;
}

@keyframes mario-walk {
    from {
        background-position: -32px 0;
    }
    to {
        background-position: -128px 0;
    }
}

.jump-animation {
    animation: mario-jump-animation 2s ease-in-out infinite;
}

@keyframes mario-jump-animation {
    0% {
        transform: translateY(0);
        background-position: -32px 0;
    }
    40% {
        transform: translateY(-30px);
        background-position: -160px 0;
    }
    50% {
        transform: translateY(-30px);
        background-position: -160px 0;
    }
    60% {
        transform: translateY(-30px);
        background-position: -160px 0;
    }
    100% {
        transform: translateY(0);
        background-position: -32px 0;
    }
}

.arrow-animation {
    animation: arrow-bounce 2s ease-in-out infinite;
}

@keyframes arrow-bounce {
    0%, 100% {
        transform: translateY(0);
        opacity: 1;
    }
    45% {
        transform: translateY(-30px);
        opacity: 1;
    }
    55% {
        transform: translateY(-30px);
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
} 