@tailwind base;
@tailwind components;
@tailwind utilities;

/* Prevent white flash on page reload */
body {
  background-color: #0c0a09; /* bg-stone-900 */
}

@layer components{
    .button {
        @apply rounded-[5px] border-white transition-all no-underline
        /* border-width: 1px; */
    }

    .project_btn {
        @apply rounded-[5px] border-white transition-all no-underline
    }
    
    .icon{
        @apply border-white border-[1px] text-white rounded-[5px] border-solid transition-all flex text-center items-center
    }
    
    .icon_item{
        @apply p-1 w-7 h-7
    }

    .icon_item_2{
        @apply w-9 h-9 ml-1
    }
    
    .button:hover > .icon{
        transform: scale(.9) translateY(-5px);
        -webkit-transform: scale(.9) translateY(-5px);
    }
    
    .button:hover > .btn_text{
        @apply opacity-100
    }
    
    .btn_text{
        @apply transition-all text-white text-xs opacity-0 absolute bottom-[-10px] font-font
    }

    .active{
        @apply bg-black
    }

    .passive{
        @apply border-2 border-black
    }

    .icon_project{
        @apply ml-2 w-9 h-9
    }

    .slider_link{
        @apply absolute border-[1px] border-black rounded-[10px] px-2 py-1 bg-black mt-1 opacity-0 invisible
    }

    .slider_arrow{
        @apply absolute border-solid border-b-black border-b-8 border-x-transparent border-x-8 border-t-0 ml-2 opacity-0 invisible
    }

    .slider_item{
        @apply relative
    }

    .slider_item:hover .slider_link{
        @apply opacity-100 transition-all visible
    }
    .slider_item:hover .slider_arrow{
        @apply opacity-100 transition-all visible
    }

    .txt{
        @apply font-font_text
    }
}

.perspective-1000 {
    perspective: 1000px;
}

/* Proje kartı hover efekti */
.project_box {
    transition: all 0.5s ease-out;
    transform-style: preserve-3d;
    transform-origin: center center;
}

/* Kart gölgeleri */
.project_box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 100%
    );
    pointer-events: none;
    transform: translateZ(-1px);
}

/* Arka plan kartları */
.background-card {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out;
}

.background-card.left {
    transform: translateX(-100px) translateZ(-50px) rotateY(15deg);
    opacity: 0.7;
}

.background-card.right {
    transform: translateX(100px) translateZ(-50px) rotateY(-15deg);
    opacity: 0.7;
}

/* Animasyon sırasında smooth geçiş */
.project_box > * {
    transition: all 0.5s ease-out;
}

/* 3D dönüş efekti için container */
.project-container {
    transform-style: preserve-3d;
    perspective: 2000px;
    position: relative;
}

/* Proje kartları için yeni stiller */
.project-stage {
    perspective: 2000px;
    transform-style: preserve-3d;
}

.project-stage > * {
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

/* Kart gölgeleri */
.project-stage > div::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

/* Arka plan kartları için gradient */
.project-stage > div:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
    pointer-events: none;
}
